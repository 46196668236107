'use client';

import { usePageError } from '@mentimeter/errors/usePageError';
import { ErrorFallback } from 'features/shell/components/error-fallback';

export default function Error({ error }: { error: Error }) {
  usePageError({
    error,
    message: 'Error loading User home',
    feature: 'user-home',
  });

  return <ErrorFallback />;
}
