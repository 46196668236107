import { MentiError } from '@mentimeter/error-utils/error';
import type { FeatureOwner } from '@mentimeter/error-utils/feature-owners';
import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

export function usePageError({
  error,
  message,
  feature,
}: {
  error: Error;
  message: string;
  feature: FeatureOwner;
}) {
  useEffect(() => {
    if (error instanceof MentiError) {
      captureException(error);
    } else {
      captureException(
        new MentiError(message, {
          cause: error,
          feature,
        }),
      );
    }
  }, [error, message, feature]);
}
